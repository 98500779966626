<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold white--text mb-2">
          {{ t('login.log_in') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('login.log_in_detail') }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="loginForm"
          @submit.prevent="loginSubmit"
        >
          <v-text-field
            v-if="!isPhoneVisible"
            v-model="logData.code"
            dense
            outlined
            rounded
            readonly
            :placeholder="t('login.enter_phone_email')"
            hide-details
            class="mb-3 white--text lg-theme"
          />

          <div
            v-if="isPhoneVisible"
            class="d-flex justify-space-between mb-3"
          >
            <v-select
              v-model="logData.phoneCode"
              class="pr-1 w-code"
              dense
              outlined
              rounded
              readonly
              :items="phoneCodeOptions"
              item-text="text"
              item-value="value"
              :append-icon="null"
              hide-details
            >
              <template v-slot:item="{ item }">
                <img
                  :src="item.image"
                  :alt="item.value"
                  width="20"
                  height="auto"
                  class="mr-2"
                >
                {{ item.text }}
              </template>
            </v-select>

            <v-text-field
              v-model="logData.code"
              class="pl-1"
              :label="t('users.phone')"
              dense
              outlined
              rounded
              readonly
              :disabled="!logData.phoneCode"
              hide-details
            />
          </div>

          <v-text-field
            ref="passwordInput"
            v-model="logData.password"
            dense
            outlined
            rounded
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="t('login.password')"
            :error-messages="errorMessages.password"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            :rules="[validators.required, validators.minLengthValidator(logData.password, 6)]"
            hide-details
            class="white--text lg-theme"
            @click:append="isPasswordVisible = !isPasswordVisible"
          >
          </v-text-field>

          <!-- <div class="d-flex align-center justify-center flex-wrap">
            <v-btn
              class="ma-0 py-4 px-1 no-bg-hover"
              style="
                text-transform: inherit;
                min-width: 60px;
              "
              text
              color="secondary"
            >
              {{ t('login.forgot_password') }}
            </v-btn>
          </div> -->

          <v-btn
            block
            color="secondary"
            type="submit"
            size="x-large"
            class="mt-6 text-capitalize"
            :disabled="!isFormValid"
          >
            {{ t('tooltip.continue') }}
          </v-btn>

          <div class="d-flex justify-center">
            <v-btn
              class="mt-1 py-2 no-bg-hover"
              style="text-transform: inherit; color: #979797;"
              text
              @click="onLoginCode"
            >
              {{ t('login.or_code') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-confusing-arrow */
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator, minLengthValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import store from '@/store'
import { useUtils } from '@core/libs/i18n'
import usePhone from '@core/utils/usePhone'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import { sendCodeSMSUser, sendCodeEmailUser } from '@api'

export default {
  setup() {
    const { router } = useRouter()
    const { t } = useUtils()
    const { phoneCodeOptions } = usePhone()
    const { encrypt } = useCryptoJs()
    const { logData, currentLogData, updateLogData } = useLogData()
    const vm = getCurrentInstance().proxy

    const loginForm = ref(null)
    const passwordInput = ref(null)

    const isPhoneVisible = ref(false)
    const isPasswordVisible = ref(false)
    const errorMessages = ref([])

    const isFormValid = computed(() => {
      if (logData.value.password && minLengthValidator(logData.value.password, 6) === true) return true

      return false
    })

    const loginSubmit = async () => {
      if (loginForm.value.validate()) {
        const body = {
          code: logData.value.type === 'EMAIL' ? logData.value.code : logData.value.fullPhone,
          type: logData.value.type,
          join_game: logData.value.joinGame,
          match_code: logData.value.matchCode,
          login_route: logData.value.loginRoute,
          password: logData.value.password,
        }
        const encryptBody = encrypt(body)

        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]
        vm.$ability.update(userAbility)

        store.dispatch('auth/loginUserAction', {
          data: encryptBody,
          redirect: logData.value.redirect,
          facility: logData.value.facility,
          field: logData.value.field,
          date: logData.value.date,
          time: logData.value.time,
        })
      }
    }

    const onLoginCode = async () => {
      let respSend = null
      let info = null
      let encryptBody = null
      if (isPhoneVisible.value) {
        info = {
          phone_number: logData.value.fullPhone,
        }
        encryptBody = encrypt(info)
        respSend = await sendCodeSMSUser(encryptBody)
      } else {
        info = {
          email: logData.value.code,
        }
        encryptBody = encrypt(info)
        respSend = await sendCodeEmailUser(encryptBody)
      }
      if (respSend.ok) {
        updateLogData(logData.value)
        router.push({ name: 'auth-access-code' })
      }
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      isPhoneVisible.value = logData.value.type === 'PHONE_NUMBER'

      setTimeout(() => {
        passwordInput.value.focus()
      }, 500)
    })

    return {
      loginForm,
      passwordInput,
      logData,
      isPasswordVisible,
      errorMessages,
      isFormValid,

      isPhoneVisible,
      phoneCodeOptions,

      // themeConfig
      appName: themeConfig.app.name,
      appLogoTitle: themeConfig.app.logoTitle,

      loginSubmit,
      onLoginCode,

      t,

      validators: {
        required,
        emailValidator,
        minLengthValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
